import React from 'react';

// External Components
import { Section, Heading, Paragraph } from '@thepuzzlers/pieces';
import { convertApiHtmlText, convertPtoSpan } from 'graphqlHooks/helper';

// Local Components
import { HorizontalDivider, PrimaryButton } from 'components';

export const Strategy = ({
  data: { headline, description, button_text, button_to }
}) => {
  return (
    <Section id="solution__strategy">
      <Headline headline={headline} />
      <Divider />
      <Description description={description} />
      <Button buttonText={button_text} buttonTo={button_to} />
    </Section>
  );
};

const Headline = ({ headline }) => {
  return (
    <Heading
      as="h3"
      dangerouslySetInnerHTML={{ __html: convertPtoSpan(headline.html) }}
      sx={{
        fontFamily: 'primary.normal',
        '& strong': {
          fontFamily: 'primary.bold'
        },
        '& > span': {
          display: 'block',
          '&:nth-of-type(2)': {
            textIndent: ['4rem', null, '5rem', null, '5.9rem', '6rem']
          }
        },

        lineHeight: 1.35,
        fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '4.8rem'],
        gridColumn: [
          '1/13',
          null,
          '3/ span 18',
          null,
          '5/ span 16',
          '5/ span 16'
        ]
      }}
    />
  );
};

const Divider = () => {
  return (
    <HorizontalDivider
      sx={{
        gridColumn: ['1/13', null, '3/ span 4', null, '5/ span 3', '5/ span 8'],
        mt: ['3.5rem', null, '3.5rem', null, '3rem', '3rem'],
        mb: ['2.5rem', null, '3.5rem', null, '3rem', '3rem']
      }}
    />
  );
};

const Description = ({ description }) => {
  return (
    <Paragraph
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(description.html) }}
      sx={{
        fontFamily: 'body.normal',
        '& > span': {
          fontFamily: 'body.boldItalic'
        },
        lineHeight: 1.75,
        fontSize: ['1.6rem', null, '1.7rem', null, '1.7rem', '1.7rem'],
        width: [null, null, null, null, null, '56.8rem'],
        gridColumn: [
          '1/13',
          null,
          '3/ span 12',
          null,
          '5/ span 10',
          '5/ span 11'
        ]
      }}
    />
  );
};

const Button = ({ buttonText, buttonTo }) => {
  return (
    <PrimaryButton
      text={buttonText}
      to={buttonTo}
      variant="buttons.primaryMedium"
      className="__strategy-button"
      sx={{
        gridColumn: ['1/13', null, '3/ span 10', null, '17/25', '18/25'],
        justifySelf: ['center', null, 'start', null, 'start', 'start'],
        mt: ['5rem', null, '5rem', null, 0, 0],
        alignSelf: [null, null, null, null, 'center', 'center']
      }}
    />
  );
};
