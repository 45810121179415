import React, { Suspense } from 'react';

// External Components
import { Paragraph, Box } from '@thepuzzlers/pieces';

const CheckMarkRive = React.lazy(() => import('components/CheckMarkRive'));

export const ListItemWithCheckMark = ({ text, index }) => {
  return (
    <Paragraph
      as="li"
      sx={{
        fontFamily: 'body.boldItalic',
        lineHeight: 1.5,
        fontSize: ['1.6rem', null, '1.7rem', null, '1.8rem', '2rem'],
        position: 'relative',
        '& ~ li': {
          mt: '2.4rem'
        }
      }}>
      {/* additional box needed to make the text and the check box not influence the list marker */}
      <Box as="span" sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Box
          as="span"
          sx={{
            flex: 1
          }}>
          {text}
        </Box>
        <CheckMark index={index} />
      </Box>
    </Paragraph>
  );
};

const CheckMark = ({ index }) => {
  return (
    <Box
      sx={{
        width: ['2.2rem', null, '2.2rem', null, '3.4rem', '3.8rem'],
        height: 'max-content',
        position: 'relative',
        top: '-0.2rem'
      }}>
      <Suspense fallback={<Box />}>
        <CheckMarkRive index={index} />
      </Suspense>
    </Box>
  );
};
