import React from 'react';

// External Components
import { Section, Box, Heading, Paragraph } from '@thepuzzlers/pieces';
import { convertApiHtmlText } from 'graphqlHooks/helper';
import { AspectRatioImageContainer, GridSpacer, SpringLine } from 'components';
import { ListItemWithCheckMark } from 'components/ListItemWithCheckMark';

export const Tools = ({ data: { title, list, image } }) => {
  return (
    <Section id="solution__tools">
      <Headline headline={title} />
      <List list={list} />
      <Image image={image} />
    </Section>
  );
};

// Elements
const Headline = ({ headline }) => {
  return (
    <Heading
      as="h2"
      dangerouslySetInnerHTML={{ __html: convertApiHtmlText(headline.html) }}
      sx={{
        fontFamily: 'primary.normal',
        lineHeight: 1.35,
        fontSize: ['3.2rem', null, '4rem', null, '4.8rem', '4.8rem'],
        '& > span': {
          fontFamily: 'primary.bold'
        },
        gridColumn: [
          '1/13',
          null,
          '1/ span 12',
          null,
          '2/ span 11',
          '2/ span 11'
        ],
        gridRow: [null, null, 1, null, 1]
      }}
    />
  );
};

const List = ({ list }) => {
  return (
    <Box
      as="ul"
      // animation values
      className="__list-item-with-check-mark-container"
      sx={{
        listStyle: 'disc',
        mt: ['4rem', null, '4rem', null, '3rem', '4rem'],
        pl: ['1.7rem', null, '2.6rem', null, '2.6rem', '2.5rem'],
        gridColumn: [
          '1/13',
          null,
          '2/ span 10',
          null,
          '3/ span 9',
          '3/ span 9'
        ],
        gridRow: [null, null, 2, null, 2]
      }}>
      {list.map((data, index) => (
        <ListItemWithCheckMark text={data.text} key={index} index={index} />
      ))}
    </Box>
  );
};

const Image = ({ image: { image, alt } }) => {
  return (
    <AspectRatioImageContainer
      src={image}
      alt={alt}
      aspectRatios={1 / 1.41}
      sx={{
        gridColumnStart: [6, null, 11, null, 12, 12],
        justifySelf: 'start',
        width: ['26.5rem', null, '34.4rem', null, '37rem', '47rem'],
        mt: ['-1rem', null, '-5.1rem', null, '-5.3rem'],
        gridRow: [null, null, '1/ span 3', null, '1/ span 3'],
        zIndex: -1
      }}>
      <SpringLine
        sx={{
          position: 'absolute',
          zIndex: -1,
          width: ['17.3rem', null, '17.3rem', null, '20.1rem', '25.4rem'],
          top: ['19.8rem', null, '17.2rem', null, '17.8rem', '25rem'],
          left: ['-13.5rem', null, '28.7rem', null, '33.7rem', '43.2rem']
        }}
      />
    </AspectRatioImageContainer>
  );
};
