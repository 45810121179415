import { Spacer } from 'components';
import React from 'react';

// External Components
import { Box } from '@thepuzzlers/pieces';
import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { Helmet } from 'react-helmet';

// Sections
import { Header, Strategy, Tools } from 'sections/solution';

// Local Components
import { Suitability } from 'components/sections';
import { useSiteMetadata } from 'gatsby-theme-thepuzzlers-core';
import { convertStringToSlug } from 'graphqlHooks/helper';
import { useCommonSectionData } from 'graphqlHooks';

const Solution = (props) => {
  const {
    title,
    description,
    headline,
    list_image,
    list_items,
    list_title,
    seo_title,
    seo_description,
    seo_short_title,
    seo_short_description,
    seo_image,
    slug
  } = props.pageContext.data;

  const { url } = useSiteMetadata();
  const { solution_process } = useCommonSectionData();

  const seoUrl = `${url}/loesung/${convertStringToSlug(slug)}/`;

  return (
    <>
      <SEO
        title={seo_title}
        description={seo_description}
        shortTitle={seo_short_title}
        shortDescription={seo_short_description}
        url={seoUrl}
      />
      <Helmet>
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoUrl} />
        <meta property="og:image" content={seo_image?.file} />

        {/* Twitter */}
        <meta property="twitter:url" content={seoUrl} />
        <meta property="twitter:image" content={seo_image?.file} />
      </Helmet>
      <Header data={{ title, headline, description }} />
      <Box
        as="main"
        sx={{
          overflow: 'hidden'
        }}>
        <Spacer height={['23rem', null, '28rem', null, '25.3rem', '26.5rem']} />
        <Tools
          data={{ title: list_title, list: list_items, image: list_image }}
        />
        <Spacer height={['14rem', null, '20rem', null, '20.9rem', '18.7rem']} />
        <Strategy data={solution_process} />
        <Spacer
          height={['18.6rem', null, '30rem', null, '25.2rem', '31.2rem']}
        />
        <Suitability />
        <Spacer height={['30rem', null, '30rem', null, '26rem', '30rem']} />
      </Box>
    </>
  );
};

export default Solution;
